import { FC } from "react";
import { Grid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import BookingItem from "./BookingItem";
import { BookingI } from "types/responses/shop";

interface Props {
  bookingList: BookingI[];
}

const BookingList: FC<Props> = ({ bookingList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        md: "repeat(3, 1fr)",
        lg: "repeat(4, 1fr)",
      }}
      rowGap={{
        base: 1,
        md: 2,
        lg: 5,
      }}
      columnGap={8}
      p={1}
    >
      {bookingList &&
        bookingList.length > 0 &&
        bookingList.map((booking) => (
          <BookingItem key={booking.bookingId} booking={booking} />
        ))}
    </Grid>
  );
};

export default BookingList;
