import { addDays, addMonths, addWeeks, endOfDay, endOfMonth, endOfWeek, endOfYear, format, isSameDay, startOfDay, startOfMonth, startOfWeek, startOfYear, parseISO, isBefore, differenceInMinutes } from "date-fns";
import { WatchOption, WatchRange } from "types/requests/shop";

export const formatDateTime = (date: Date): string => {
    return format(date, "yyyy-MM-dd HH:mm:ss")
}

export const getWatchRangeFromWatchOption = (watchOption: WatchOption): WatchRange => {
    const currentDate = new Date();

    switch (watchOption) {
        case "Today":
            return {
                startDate: startOfDay(currentDate).toISOString(),
                endDate: endOfDay(currentDate).toISOString()
            }
        case "Tomorrow":
            return {
                startDate: startOfDay(addDays(currentDate, 1)).toISOString(),
                endDate: endOfDay(addDays(currentDate, 1)).toISOString(),
            }
        case "This Week":
            return {
                startDate: startOfWeek(currentDate, { weekStartsOn: 0 }).toISOString(),
                endDate: endOfWeek(currentDate, { weekStartsOn: 0 }).toISOString(),
            }
        case "Next Week":
            return {
                startDate: startOfWeek(addWeeks(currentDate, 1), { weekStartsOn: 0 }).toISOString(),
                endDate: endOfWeek(addWeeks(currentDate, 1), { weekStartsOn: 0 }).toISOString(),
            }
        case "This Month":
            return {
                startDate: startOfMonth(currentDate).toISOString(),
                endDate: endOfMonth(currentDate).toISOString(),
            }
        case "Next Month":
            return {
                startDate: startOfMonth(addMonths(currentDate, 1)).toISOString(),
                endDate: endOfMonth(addMonths(currentDate, 1)).toISOString(),
            }
        case "This Year":
            return {
                startDate: startOfYear(currentDate).toISOString(),
                endDate: endOfYear(currentDate).toISOString(),
            }
        default:
            return {
                startDate: (new Date()).toISOString(),
                endDate: (new Date()).toISOString(),
            }
    }
}

export const headerDisplaySelectedWatchRange = (date: WatchRange): string => {
    const start = date.startDate;
    const end = date.endDate;

    if (isSameDay(start, end)) {
        return format(start, "yyyy-MM-dd");
    } else {
        return format(start, "yyyy-MM-dd") + " : " + format(end, "yyyy-MM-dd")
    }
}

export const convertUTCToLocalTime = (date: string): string => {
    const localDate = parseISO(date).toLocaleString();
    return format(localDate, "yyyy-MM-dd HH:mm");
}

export const validateBookingTimes = (startTime: Date, endTime: Date) => {
    const currentTime = new Date();

    // Initialize common return object
    const validationResult = {
        valid: true,
        message: 'Booking times are valid.'
    };

    // Validation 1: Start time should be before end time
    if (!isBefore(startTime, endTime)) {
        validationResult.valid = false;
        validationResult.message = 'Start time must be earlier than end time.';
        return validationResult; // Stop at first error
    }

    // Validation 2: Both start and end times should be in the future
    if (isBefore(startTime, currentTime)) {
        validationResult.valid = false;
        validationResult.message = 'Start time must be in the future.';
        return validationResult; // Stop at first error
    }

    if (isBefore(endTime, currentTime)) {
        validationResult.valid = false;
        validationResult.message = 'End time must be in the future.';
        return validationResult; // Stop at first error
    }

    // Validation 3: Ensure there's at least a 10-minute gap between start and end times
    const minTimeGap = 10; // 10 minutes
    if (differenceInMinutes(endTime, startTime) < minTimeGap) {
        validationResult.valid = false;
        validationResult.message = 'There should be at least a 10-minute gap between start and end times.';
        return validationResult; // Stop at first error
    }

    // If all validations pass, return valid
    return validationResult;
};