import { Button, GridItem, HStack, Tag, Text } from "@chakra-ui/react";
import { FC } from "react";
import { BookingI } from "types/responses/shop";
import { convertUTCToLocalTime } from "utils/date";

interface Props {
  booking: BookingI;
}

const BookingItem: FC<Props> = ({ booking }) => {
  return (
    <GridItem w="full">
      <HStack
        justify="space-between"
        w="full"
        bg="black"
        borderRadius={8}
        p={2}
      >
        <Text color="#fff" noOfLines={1}>
          {convertUTCToLocalTime(booking.bookingStart)}
        </Text>
        -
        <Text color="#fff" noOfLines={1}>
          {convertUTCToLocalTime(booking.bookingEnd)}
        </Text>
      </HStack>
    </GridItem>
  );
};

export default BookingItem;
